
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import ScrollToPlugin from "gsap/ScrollToPlugin";
import SplitText from 'gsap/SplitText';
import ScrollSmoother from 'gsap/ScrollSmoother';

import Swiper from 'swiper';
import { Thumbs, Controller, Autoplay, EffectCreative } from 'swiper/modules';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, SplitText, ScrollSmoother);

function isSmall() {
    return (window.innerWidth < 640 && window.innerHeight > window.innerWidth);
}
function isMobile() {
    return mobileSniff();// /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(window.navigator.userAgent);
}
function iPhoneSniff() {
    return /iP(ad|hone|od).*OS/.test(window.navigator.userAgent);
}
function androidSniff() {
    return /Android/.test(window.navigator.userAgent);
}
function mobileSniff() {
    return iPhoneSniff() || androidSniff();
}

let smoother;

window.addEventListener('load', e => {

    ScrollTrigger.create({
        trigger: "body",
        start: "top+=70 top",
        end: 100000,
        toggleClass: {
            targets: [".navbar"],
            className: "scrolled",
        }
    })

    const hSwiper2 = new Swiper('.HeroSwiper2', {
        modules: [Thumbs, Controller, Autoplay],
        // slidesPerView: 4,
        watchSlidesProgress: true,
        slideThumbActiveClass: 'active',
        speed: 600,
        breakpoints: {
            576: {
                slidesPerView: 1
            },
            768: {
                slidesPerView: 2
            },
            992: {
                slidesPerView: 4
            },
        }
    })
    const hSwiper = new Swiper('.HeroSwiper', {
        modules: [Thumbs, Controller, Autoplay, EffectCreative],
        loop: true,
        draggable: true,
        speed: 600,
        effect: "creative",
        creativeEffect: {
            prev: {
                shadow: true,
                translate: [0, 0, -500],
            },
            next: {
                translate: ["100%", 0, 0],
            },
        },
        thumbs: {
            swiper: hSwiper2,
        },
        autoplay: {
            delay: 2500,
            disableOnInteraction: false
        },
        on: {
            slideChange: function (s) {
                hSwiper2.slides.forEach(slide => {
                    const pr = slide.querySelector(".swiper-slide-progress");
                    if (pr) pr.style.width = "0%"
                })
            },
            autoplayTimeLeft: function (s, time, progress) {
                const activeThumbProgress = hSwiper2.el.querySelector(".swiper-slide-thumb-active .swiper-slide-progress");
                if (activeThumbProgress) activeThumbProgress.style.width = ((1 - progress) * 100) + "%";
            }
        }
    })


    const hsplitText = document.querySelectorAll('.split-text .subtitle');
    if (hsplitText.length) {
        hsplitText.forEach(text => {
            const _w = new SplitText(text, { type: 'lines', linesClass: 'line' });
            _w.lines.forEach((line) => {
                line.setAttribute('style', '--line-size: 100%');
                const sz = { size: '100%' };
                gsap.to(sz, {
                    size: '0%',
                    ease: 'none',
                    onUpdate: () => { line.setAttribute('style', `--line-size: ${sz.size}`) },
                    scrollTrigger: {
                        trigger: line,
                        start: 'clamp(top bottom-=25%)',
                        end: !isMobile() ? 'clamp(top bottom-=30%)' : 'clamp(top bottom-=27%)',
                        scrub: true,
                    }
                })
            })
        })
        // gsap.to('.services-heading', {
        //     x: '-=10%',
        //     ease: 'none',
        //     scrollTrigger: {
        //         trigger: '.Support',
        //         start: 'clamp(top-=60 bottom)',
        //         end: 'clamp(bottom+=500)',//(proj[0].scrollWidth + document.documentElement.clientWidth),
        //         scrub: 3,
        //     }
        // })
    }

    const accButtons = document.querySelectorAll('.accordion-button .title');
    const buttons = document.querySelectorAll('.accordion-button');
    accButtons.forEach(btn => {
        btn.addEventListener('click', function(e){
            buttons.forEach(b => b.classList.add("collapsed"))
            const _btn = e.target;
            if (_btn.classList.contains('collapsed')) {
                _btn.parentElement.parentElement.classList.add('collapsed')
            } else {
                _btn.parentElement.parentElement.classList.remove('collapsed')
            }
        })
        const toggler = btn.parentElement.nextSibling.nextSibling;
        if (toggler) toggler.addEventListener('click', function(e){
            const _btn = e.target.previousSibling.previousSibling.querySelector('.title');
            _btn.click();
        })
    })
    


    if (!isMobile()) {
        smoother = ScrollSmoother.create({
            smooth: 1, // how long (in seconds) it takes to "catch up" to the native scroll position
            effects: true, // looks for data-speed and data-lag attributes on elements
            smoothTouch: 0.1, // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
        });
        const hash = window.location.hash;
        if (hash) {
            setTimeout(() => {
                smoother.scrollTo(hash, true);
            }, 500);
        }
    } else {const hash = window.location.hash;
        if (hash) {
            gsap.to(window, { scrollTo: hash });
        }
    }

})


const links = document.querySelectorAll(".navbar .nav-link");
links.forEach(link => {
    link.addEventListener("click", e => {
        e.preventDefault();
        let href = e.target.href;
        href = href.substr(href.indexOf('#'))
        // console.log(href);
        if (!isMobile()) {
            smoother.scrollTo(href, true);
        } else {
            gsap.to(window, { scrollTo: href });
        }

        document.querySelector(".navbar-collapse").classList.remove("show");

        // or you could animate the scrollTop:
        // gsap.to(smoother, {
        //  scrollTop: smoother.offset(href, "center center"),
        //  duration: 1
        // });
        return false;
    });
})

const inlinelinks = document.querySelectorAll(".VisitUs .btn");
inlinelinks.forEach(link => {
    link.addEventListener("click", e => {
        e.preventDefault();
        let href = e.target.href;
        href = href.substr(href.indexOf('#'))
        // console.log(href);
        if (!isMobile()) {
            smoother.scrollTo(href, true);
        } else {
            gsap.to(window, { scrollTo: href });
        }

        return false;
    });
})
